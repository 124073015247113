<template>
  <div class="parallax-home-page">
    <HeaderMultiPageRoute class="parallax-header">
      <img slot="logo" src="../assets/img/logo/logo.png" />
    </HeaderMultiPageRoute>

    <!-- Start Hero Area -->
    <div
      class="slider-area paralx-slider parallax fullscreen d-flex align-center rn-bg-position-fixed"
      :style="{ backgroundImage: 'url(' + src1 + ')' }"
    >
      <v-container>
        <v-row class="row">
          <v-col lg="12">
            <div class="text-center content bg-layer">
              <h2>Welcome, Recruit!</h2>
              <p>
                In a distant future, Earth is plagued by mutated versions of the Coronavirus.
                <br /> It is up to The Foundation to combat the virus.

<br /> <a href="play.supa.foundation">Join us and save humanity in our SUPAVerse!</a>
                
              </p>
               <router-link class="mt--30 rf_btn" to="/supaverse"
                >Enter SUPAVerse</router-link
              >
               <router-link class="mt--30 rf_btn" to="/whitepaper"
                >Whitepaper</router-link
              >
                 <router-link class="mt--30 rf_btn" to="/portal"
                >Portal</router-link
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Hero Area -->
    <!-- Start Hero Area -->
    <div
      class="slider-area paralx-slider parallax fullscreen d-flex align-center rn-bg-position-fixed"
      :style="{ backgroundImage: 'url(' + src3 + ')' }"
    >
      <v-container>
        <v-row class="row">
          <v-col lg="12">
            <div class="text-center content">
              <h2>Overview</h2>
              <p>
                SUPA Foundation is a GameFi metaverse on the Fantom Blockchain.
                <br />Join us on Discord to follow our rich storytelling and quests.
                <br /> Our $SUPA token has launched and is listed on <a href="https://spookyswap.finance" target="blank">SpookySwap</a>, <a href="https://spiritswap.finance" target="blank">SpiritSwap</a> & <a href="https://hotbit.io" target="blank">HotBit</a>.
                               <br />You can now purchase our SUPAOrganism NFTs at our <a href="https://portal.supa.foundation" >Portal</a>.

           <br />The SUPAVerse game is currently in development. See our <a href="http://supa.foundation/whitepaper" target="blank">whitepaper</a> for the Roadmap.

              </p>
               <router-link class="mt--30 rf_btn" to="/twitter"
                >Twitter</router-link
              >
              <router-link class="mt--30 rf_btn" to="/discord"
                >Discord</router-link
              >
              <router-link class="mt--30 rf_btn" to="/telegram"
                >Telegram</router-link
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Hero Area -->
    <!-- Start Hero Area -->
    <div
      class="slider-area paralx-slider parallax fullscreen d-flex align-center rn-bg-position-fixed"
      :style="{ backgroundImage: 'url(' + src2 + ')' }"
    >
      <v-container>
        <v-row class="row">
          <v-col lg="12">
            <div class="text-center content">
              <h2>NFT SHOWCASE</h2>
          <p>
<video width="80%"  controls>
  <source src="../assets/video.mp4" type="video/mp4">
Your browser does not support the video tag.
</video></p>
<p class="portal-button"><router-link to="/portal"><img src="../assets/img/portal-button.jpg"/></router-link></p>
   
              
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Hero Area -->



    <!-- Start Hero Area -->
    <div
      class="slider-area paralx-slider1 parallax fullscreen d-flex align-center rn-bg-position-fixed"
      :style="{ backgroundImage: 'url(' + src4 + ')' }"
    >
      <v-container>
        <v-row class="row">
          <v-col lg="12">
            <div class="text-center content">
              <h2>Founders</h2>
             <div class="row">
			<div class="col-md-4">
				<div class="card-box text-center">
					<div class="user-pic">
						<img src="../assets/img/brian.png" class="img-fluid" alt="User Pic">
					</div>
					<h4>Brian Ng</h4>
					<h6>CEO/CTO</h6>
					<hr>
          <p>Avid Crypto and Blockchain enthusiast who has over 15 years experience coding. His passion for Tech and Programming began during his early youth which has led him to experiment and build various backend services, frontend web and Mobile Apps. </p>
					<hr>
				</div>
			</div>

			<div class="col-md-4">
				<div class="card-box text-center">
					<div class="user-pic">
						<img src="../assets/img/princeton.png" class="img-fluid" alt="User Pic">
					</div>
					<h4>Princeton</h4>
					<h6>Digital Marketing & Strategist</h6>
					<hr>
					<p>Started tinkering  with computers at an age of 10 and building his first BTC mining machine in 2017. These days he hangs out in the Crypto and NFT space looking out for new updates in these spaces. Holds 10 years worth of Customer Experience and manages the Digital space for SUPA Foundation.</p>
					<hr>
				</div>
			</div>

			<div class="col-md-4">
				<div class="card-box text-center">
					<div class="user-pic">
						<img src="../assets/img/andy.png" class="img-fluid" alt="User Pic">
					</div>
					<h4>Andy Lim</h4>
					<h6>COO</h6>
					<hr>
					<p>A gamer at heart who loves looking into innovation to constantly change the gaming landscape. His love for trading card games began during high school. Also a lover of fictional works and constantly delves into intricacies of alternate multiverses.  </p>
					<hr>
				</div>
			</div>
		</div>
              
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
        <div
      class="slider-area paralx-slider parallax fullscreen d-flex align-center rn-bg-position-fixed"
      :style="{ backgroundImage: 'url(' + src5 + ')' }"
    >
      <v-container>
        <v-row class="row">
          <v-col lg="12">
            <div class="text-center content">
              <h2>Partners & Investors</h2>
              <img src="../assets/img/bg/partners.png" />
             
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Hero Area -->

    <Footer />
  </div>
</template>

<script>
  import HeaderMultiPageRoute from "../components/header/HeaderMultiPageRoute";
  import Footer from "../components/footer/Footer";
  export default {
    components: {
      HeaderMultiPageRoute,
      Footer,
    },
    data() {
      return {
        src1: require("../assets/img/bg/1.jpeg"),
        src2: require("../assets/img/bg/2.jpg"),
        src3: require("../assets/img/bg/3.jpg"),
        src4: require("../assets/img/bg/4.jpg"),
        src5: require("../assets/img/bg/5.jpg"),

      };
    },
  };
</script>

<style lang="scss" scoped>
  .parallax-home-page .logo {
    img {
      @media only screen and (max-width: 575px) {
        max-width: 80%;
      }
    }
  }


.portal-button img{
  border-radius:90px;
}
.user-pic {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 100%;
    margin: 20px auto 20px;
    border-left: 3px solid #ddd;
    border-right: 3px solid #ddd;
    border-top: 3px solid #007bff;
    border-bottom: 3px solid #007bff;
    transition: 0.5s;
}
.card-box:hover .user-pic {
    transform: scale(1.1);
}
.card-box {
    padding: 15px;
    background-color: rgba(253,253,253,0.4);
    margin: 20px 0px;
    border-radius: 10px;
    border: 1px solid #eee;
    box-shadow: 0px 0px 8px 0px #d4d4d4;
    transition: 0.5s;
}
.card-box:hover {
	border: 1px solid #007bff;
}
.card-box p {
    color: #fff;
       text-shadow:
            0.1em 0 black,
            0 0.1em black,
            -0.1em 0 black,
            0 -0.1em black,
            -0.1em -0.1em black,
            -0.1em 0.1em black,
            0.1em -0.1em black,
            0.1em 0.1em black;
}

</style>
