<template>
  <!-- Footer -->
  <footer class="-rn-footer" id="footer">
    <div class="rn-footer-wrapper footer">
  
      <div class="footer-wrapper ptb--100">
        <v-container fluid>
          <v-row>
            <!-- Start Single Wedget -->
          
       
            <v-col lg="4" md="4" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Contact</h3>
                <div class="content">
                  <div class="address">
                    <p>
                     contact[at]supa.foundation
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
            </v-col>
              <v-col lg="4" md="4" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Documents</h3>
                <div class="content">
                  <ul class="quick-link">
                    <li>
                      <router-link to="/whitepaper">
                      Whitepaper
                   </router-link>
                    </li>
                    <li>
                    <router-link to="/termsofuse">
                     Terms of Use
                   </router-link>
                    </li>
                    <li>
                    <router-link to="/privacypolicy">
                      Privacy Policy
                   </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
            <v-col lg="4" md="4" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Quick Links</h3>
                <div class="content">
                  <ul class="quick-link">
                    <li>
                      <router-link to="/twitter">
                      Twitter
                   </router-link>
                    </li>
                    <li>
                    <router-link to="/discord">
                      Discord
                   </router-link>
                    </li>
                    <li>
                    <router-link to="/telegram">
                      Telegram
                   </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <FooterTwo />
  </footer>
  <!--// Footer -->
</template>

<script>
  import FooterTwo from "./FooterTwo";
  export default {
    components: {
      FooterTwo,
    },
    data() {
      return {
        quickLink: [
          {
            name: "Blog",
            RouteName: "/blog",
          },
          {
            name: "Service",
            RouteName: "service",
          },
          {
            name: "Portfolio",
            RouteName: "portfolio",
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
