<template>
  <!-- Footer -->
  <div class="copyright">
    <v-container fluid>
      <v-row>
        <v-col lg="12" md="12" sm="12" cols="12">
          <div class="copyright-inner">
            <p>
           
              <a
                href="https://supa.foundation"
                
                >SUPAFDN LLC</a
              >({{ new Date().getFullYear() }}) 
            </p>
          </div>
        </v-col>
 
      </v-row>
    </v-container>
  </div>
  <!--// Footer -->
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss">
  .copyright-inner {
    p {
      a {
        color: #222222;
        &:hover {
          color: #000;
        }
      }
    }
  }
</style>
