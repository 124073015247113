import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
//import Missing from '../views/404.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: Home,
        meta: {
            title: 'SUPA FOUNDATION'
        }
    },
    {
      path: "/privacypolicy",
      beforeEnter() {
        location.href = "https://supa.foundation/privacypolicy.pdf";
      },
    },
    {
      path: "/termsofuse",
      beforeEnter() {
        location.href = "https://supa.foundation/termsofuse.pdf";
      },
    },
    {
      path: "/supaverse",
      beforeEnter() {
        location.href = "https://play.supa.foundation";
      },
    },
    {
        path: "/whitepaper",
        beforeEnter() {
          location.href = "https://supa.foundation/SUPAWhitepaper.pdf";
        },
      },
      {
        path: "/portal",
        beforeEnter() {
          location.href = "https://portal.supa.foundation";
        },
      },
      {
        path: "/discord",
        beforeEnter() {
          location.href = "https://discord.com/invite/7zP4fs3U3N";
        },
      },
      {
        path: "/twitter",
        beforeEnter() {
          location.href = "https://twitter.com/supafoundation";
        },
      },
      {
        path: "/telegram",
        beforeEnter() {
          location.href = "https://t.me/supafoundation";
        },
      },
   
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router